<template>
  <div class="modal-content" v-if="options">
    <!--    type:{{type}}-->
    <!--    <pre>{{userNode.id}}</pre>-->
    <!--  nodeid:  <pre>{{userNode.id}}</pre>-->
    <!--        <pre>{{userNode}}</pre>-->
    <!--    id<pre>{{id}}</pre>-->
    <div class="row">
      <div class="col-lg-3 pe-lg-0">
        <div class="rounded-0 modal-header bgc-paars c-200 text-white">
          <span class="text-sm modal-title overline ">{{
            $t("message.profile")
          }}</span>
        </div>
        <div class="modal-body col border-end">
          <nav class="usermodal-nav">
            <ul class="nav nav-pills flex-column" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="
                    w-100
                    text-start text-capitalize
                    pill-paars
                    nav-link
                    active
                  "
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  :class="
                    errors.includes('firstname_is_required') ||
                    errors.includes('lastname_is_required')
                      ? 'c-koraalrood c-200'
                      : ''
                  "
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  <span class="text-sentence">{{ $t("message.personal_data") }}</span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="w-100 text-start text-capitalize pill-paars nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  <span class="text-sentence">{{ $t("message.contact_data") }}</span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="w-100 text-start text-capitalize pill-paars nav-link"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  :class="
                    errors.includes('passwords_do_not_match') ||
                    errors.includes('password_is_required')
                      ? 'c-koraalrood c-200'
                      : ''
                  "
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  <span class="text-sentence">{{ $t("message.account") }}</span>
                </button>
              </li>
              <li
                v-if="type == 'settings'"
                class="nav-item"
                role="presentation"
              >
                <button
                  class="w-100 text-start text-capitalize pill-paars nav-link"
                  id="pills-professional-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-professional"
                  type="button"
                  role="tab"
                  aria-controls="pills-professional"
                  aria-selected="false"
                >
                  <span class="text-sentence">{{ $t("message.professional") }}</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <form
        autocomplete="off"
        @submit.prevent="saveUser"
        class="col-lg-9 ps-lg-0"
      >
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div class="rounded-0 modal-header bgc-paars c-50">
              <span class="text-sm overline modal-title ">{{
                $t("message.personal_data")
              }}</span>
              <!--          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
            </div>
            <div class="modal-body col border-bottom-0">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="" class="text-capitalize text-sentence">
                    {{ $t("message.first_name") }}
                  </label>
                  <input
                    v-model="firstname"
                    type="text"
                    class="w-100 input-lg"
                  />
                  <p
                    class="c-koraalrood c-200 text-sentence"
                    v-if="errors.includes('firstname_is_required')"
                  >
                    {{ $t("message.firstname_is_required") }}
                  </p>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="" class="text-capitalize text-sentence">
                    {{ $t("message.last_name") }}
                  </label>
                  <input
                    v-model="lastname"
                    type="text"
                    class="w-100 input-lg"
                  />
                  <p
                    class="c-koraalrood c-200 text-sentence"
                    v-if="errors.includes('lastname_is_required')"
                  >
                    {{ $t("message.lastname_is_required") }}
                  </p>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="" class="text-capitalize text-sentence">
                    {{ $t("message.birthdate") }}
                  </label>
                  <input
                    v-model="birthdate"
                    type="date"
                    class="w-100 input-lg"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label for="gender" class="text-capitalize text-sentence">
                    {{ $t("message.gender") }}
                  </label>
                  <select
                    v-model="gender"
                    name="gender"
                    id="gender"
                    class="w-100 input-lg"
                  >
                    <option value="" disabled selected>{{ $t("message.selectAnOption") }}</option>
                    <template
                        v-bind:key="option"
                        v-for="option in getList('gender')"
                    >
                      <option :value="option['value']">
                        {{
                          options.localized[$i18n.locale].listtranslations[
                              option.key
                              ] || option.key
                        }}
                      </option>
                    </template>
                  </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="" class="text-capitalize text-sentence">
                    {{ $t("message.maritalstate") }}
                  </label>
                  <select
                    v-model="maritalstate"
                    name="maritalstate"
                    id="maritalstate"
                    class="w-100 input-lg"
                  >
                    <option value="">{{ $t("message.selectAnOption") }}</option>
                    <template
                      v-bind:key="option"
                      v-for="option in getList('maritalstate')"
                    >
                      <option :value="option['value']">
                        {{
                          options.localized[$i18n.locale].listtranslations[
                            option.key
                          ] || option.key
                        }}
                      </option>
                    </template>
                  </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="" class="text-capitalize text-sentence">
                    {{ $t("message.education") }}
                  </label>
                  <select
                    v-model="education"
                    name="education"
                    id="education"
                    class="w-100 input-lg"
                  >
                    <option value="">{{ $t("message.selectAnOption") }}</option>
                    <option
                      v-bind:key="option"
                      v-for="option in getList('education')"
                      :value="option.value"
                    >
                      {{
                        options.localized[$i18n.locale].listtranslations[
                          option.key
                        ] || option.key
                      }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="" class="text-capitalize text-sentence">
                    {{ $t("message.transport") }}
                  </label>
                  <select
                    v-model="transportation"
                    name="transportation"
                    id="transportation"
                    class="w-100 input-lg"
                  >
                    <option value="">{{ $t("message.selectAnOption") }}</option>
                    <option
                      v-bind:key="option"
                      v-for="option in getList('transport')"
                      :value="option.value"
                    >
                      {{
                        options.localized[$i18n.locale].listtranslations[
                          option.key
                        ] || option.key
                      }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="language" class="text-capitalize text-sentence">
                    {{ $t("message.language") }}
                  </label>
                  <select
                    v-model="language"
                    name="language"
                    id="language"
                    class="w-100 input-lg"
                  >
                  <option :value="null" selected>{{ $t("message.selectAnOption") }}</option>
            <option
              v-for="locale in $i18n.availableLocales"
              :key="`locale-${locale}`"
              :value="locale"
            >
              {{ locale.split("-")[0] }}
            </option>
          </select>
                </div>
                <div class="col-auto mb-3">
                  <label class="text-capitalize" for="childcount">{{
                    $t("message.number_of_children")
                  }}</label>&nbsp;
                  <input
                    v-model="children"
                    autocomplete="off"
                    name="childcount"
                    id="childcount"
                    style="width: 42px"
                    type="text"
                    class="d-inline me-2 input-lg"
                    placeholder="0"
                  />
                  <div class="btn-group">
                    <button
                      type="button"
                      @click.prevent="children > 0 ? children-- : false"
                      class="btn btn-sm"
                    >
                      <img
                        src="../../../assets/icons/icon-navigation-subtract.svg"
                        alt=""
                      />
                    </button>
                    <button
                      type="button"
                      @click.prevent="children++"
                      class="btn btn-sm"
                    >
                      <img
                        src="../../../assets/icons/icon-navigation-add.svg"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div class="rounded-0 modal-header bgc-paars c-50">
              <span class="text-sm overline modal-title ">{{
                $t("message.contact_data")
              }}</span>
            </div>
            <div class="modal-body col border-bottom-0">
              <div class="row">
                <div class="col-md-5 col-lg-6 mb-3">
                  <label for="" class="text-capitalize text-sentence">{{
                    $t("message.street")
                  }}</label>
                  <input v-model="street" type="text" class="input-lg w-100" />
                </div>
                <div class="col-md-2 mb-3">
                  <label for="" class="text-capitalize text-sentence">{{
                    $t("message.nr")
                  }}</label>
                  <input
                    v-model="streetnumber"
                    type="text"
                    class="input-lg w-100"
                  />
                </div>
                <div class="col-md-2 mb-3">
                  <label for="" class="text-capitalize text-sentence">{{
                    $t("message.bus")
                  }}</label>
                  <input v-model="bus" type="text" class="input-lg w-100" />
                </div>
                <div class="col-md col-lg-2 mb-3">
                  <label for="" class="text-capitalize text-sentence">{{
                    $t("message.postal_code")
                  }}</label>
                  <input
                    v-model="postalcode"
                    type="text"
                    class="input-lg w-100"
                  />
                </div>
                <div class="col-md-5 col-lg-6 mb-3">
                  <label for="" class="text-capitalize text-sentence">{{
                    $t("message.stateprovince")
                  }}</label>
                  <input
                    v-model="stateprovince"
                    type="text"
                    class="input-lg w-100"
                  />
                </div>
                <div class="col-md-7 col-lg-6 mb-3">
                  <label for="" class="text-capitalize text-sentence">{{
                    $t("message.city")
                  }}</label>
                  <input v-model="city" type="text" class="input-lg w-100" />
                </div>
                <div class="col-lg-6 mb-3">
                  <label for="country" class="text-capitalize text-sentence">{{
                    $t("message.country")
                  }}</label>
                  <select
                    v-model="country"
                    name="country"
                    id="country"
                    class="input-lg w-100"
                  >
                    <option
                      v-bind:key="country"
                      v-for="country in countries"
                      :value="country"
                      class="text-sentence"
                    >
                      {{ $t("message.country_" + country) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <div class="rounded-0 modal-header bgc-paars c-50">
              <span class="text-sm overline modal-title ">{{
                $t("message.account")
              }}</span>
            </div>
            <div class="modal-body col border-bottom-0">
              <div class="row">
                <div class="col-md-6">
                  <div class="row" id="password_reset_row">
                  <div class="col-md-10 col-sm-10 mb-3">
                    <label for="" class="text-capitalize text-sentence">
                      {{ $t("message.email") }}
                    </label>
                    <!--  todo: needs backend security  -->
                    <input
                        v-model="email"
                        type="text"
                        :class="$route.name === 'profile' ? 'disabled' : ''"
                        :disabled="$route.name === 'profile'"
                        class="w-100 input-lg"
                        style="max-width: 450px"
                    />
                    <p
                        class="c-koraalrood c-200 text-sentence"
                        v-if="errors.includes('email_is_required')"
                    >
                      {{ $t("message.email_is_required") }}
                    </p>
                  </div>
                  <button
                    v-if="$route.name !== 'profile'"
                    type="button"
                    class="btn btn-secondary col-md-2 col-sm-2"
                    id="password_reset_button"
                    @click="resetPassword"
                  >
                    <span v-show="!loadingResetMail"><i  class="far fa-sync-alt"></i></span>
                    <span v-show="loadingResetMail"><i class="far fa-sync-alt fa-spin"></i></span>
                  </button>
                  </div>
                  <p v-if="error" class="text-sentence mb-0 c-koraalrood c-200">
                    {{ error }}
                  </p>
                  <div class="col-md-12 mb-3">
                    <label for="change-password" class="text-capitalize text-sentence">
                      {{ $t("message.change_password") }}
                    </label>
                    <input
                        autocomplete="false"
                        id="change-password"
                        name="change-password"
                        v-model="newPassword"
                        type="password"
                        class="w-100 input-lg"
                        style="max-width: 450px"
                    />
                    <p
                        class="c-koraalrood c-200 text-sentence"
                        v-if="errors.includes('password_is_required')"
                    >
                      {{ $t("message.password_is_required") }}
                    </p>
                    <p
                        class="c-koraalrood c-200 text-sentence"
                        v-if="errors.includes('password_too_short')"
                    >
                      {{ $t("message.password_too_short", { amt: 6 }) }}
                    </p>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="confirm-password" class="text-capitalize text-sentence">
                      {{ $t("message.confirm_password") }}
                    </label>
                    <input
                        v-model="confirmPassword"
                        name="confirm-password"
                        id="confirm-password"
                        type="password"
                        class="w-100 input-lg"
                        style="max-width: 450px"
                    />
                    <p
                        class="c-koraalrood c-200 text-sentence"
                        v-if="errors.includes('passwords_do_not_match')"
                    >
                      {{ $t("message.passwords_do_not_match") }}
                    </p>
                  </div>
                  <div
                      v-if="!userNode.id"
                      class="col-md-12 d-flex align-items-center"
                  >
                    <checkbox
                        class="position-relative h-auto d-block w-auto"
                        v-model="notifyUser"
                    ></checkbox>
                    <label for="" class="text-capitalize text-sentence">
                      {{ $t("message.notify_user_account") }}
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div v-if="userNode.id && userSubmissions < 1" class="text-md-end">
                    <button @click.prevent="handleDelete" class="text-danger border-0 bg-transparent btn-delete ">
                      <span class="text-sentence">{{ $t("message.deleteUserAccount") }}</span>
                    </button>
                  </div>
                </div>
                <!--                has: {{userSubmissions}}-->
              </div>
            </div>
          </div>
          <!--          <div class="tab-content" id="pills-tabContent">-->
          <div
            v-if="type == 'settings'"
            class="tab-pane fade"
            id="pills-professional"
            role="tabpanel"
            aria-labelledby="pills-professional-tab"
          >
            <div class="rounded-0 modal-header bgc-paars c-50">
              <span class="text-sm overline modal-title">{{
                $t("message.professional")
              }}</span>
              <!--          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
            </div>
            <div class="modal-body col border-bottom-0">
              <div class="row">
                <div class="col-md-6 mb-3" v-if="type == 'settings'">
                  <label for="" class="text-capitalize text-sentence">
                    {{ $t("message.jobtitle") }}
                  </label>
                  <select
                    v-model="jobtitle"
                    name=""
                    id=""
                    class="w-100 input-lg"
                  >
                    <option
                      v-bind:key="option"
                      v-for="option in getList('jobtitle')"
                      :value="option.value"
                    >
                      {{
                        options.localized[$i18n.locale].listtranslations[
                          option.key
                        ] || option.key
                      }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mb-3" v-if="type == 'settings'">
                  <label
                    for=""
                    class="text-capitalize text-sentence"
                    v-if="type == 'settings'"
                    >{{ $t("message.seniority") }}</label
                  >
                  <input
                    v-model="seniority"
                    class="w-100 input-lg"
                    type="text"
                  />
                </div>
                <div class="col-md-6 mb-3" v-if="type == 'settings'">
                  <label for="" class="text-capitalize text-sentence">
                    {{ $t("message.employmentstart") }}
                  </label>
                  <input
                    v-model="employmentstart"
                    type="date"
                    class="w-100 input-lg"
                  />
                </div>
                <div class="col-md-6 mb-3" v-if="type == 'settings'">
                  <label for="" class="text-capitalize text-sentence">
                    {{ $t("message.employmentend") }}
                  </label>
                  <input
                    v-model="employmentend"
                    type="date"
                    class="w-100 input-lg"
                  />
                </div>
                <div class="col-md-6 mb-3" v-if="type == 'settings'">
                  <label for="" class="text-capitalize text-sentence">
                    {{ $t("message.company_branch") }}
                  </label>
                  <select name="" id="" v-model="companybranch" class="w-100 input-lg">
                    <option :value="null">{{ $t('message.no_branch') }}</option>
                    <option v-for="branch in branches" :key="branch.id" :value="branch.id">{{ branch.title }}</option>
                  </select>
                </div>
                <div class="col-md-12 row" v-if="type == 'settings'">
                  <div class="col-12">
                    <label for="" class="text-sentence">{{ $t("message.tags") }}</label>
                  </div>
                  <div class="col-md-3">
                    <select-search
                      :placeholder="$t('message.add_tag')"
                      :ajax="true"
                      :query="tagQuery"
                      :label="'title'"
                      :value="'id'"
                      @selectOption="addTag"
                    />
                  </div>
                  <div class="col-md-9">
                    <span
                      v-for="tag in userNodetags"
                      v-bind:key="tag.id"
                      class="
                        bgc-theme
                        fw-normal
                        c-300
                        me-2
                        mb-2
                        badge badge-secondary
                      "
                    >
                      <span>
                        <span class="title me-2 c-body">{{ tag.title }}</span>
                        <button
                          @click.prevent="removeTag(tag.id)"
                          class="ms-1 btn btn-link p-0 c-body"
                        >
                          <i class="far fa-trash-alt"></i>
                        </button>
                      </span>
                    </span>

                    <!--                    <div v-for="tag in usertags" :key="tag.id" class="mx-2 d-inline-block bgc-theme c-200 rounded-pill py-2 px-4">-->
                    <!--                      {{ tag.title }}-->
                    <!--                      <button @click="removeTag(tag.id)" class="border-0 bg-transparent">-->
                    <!--                        <i class="far fa-trash"></i>-->
                    <!--                      </button>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--          </div>-->
        </div>
        <!--       tags:-->
        <!--        <pre>-->
        <!--          {{userNode}}-->
        <!--        </pre>-->
        <div class="modal-footer border-top-0">
          <button
            v-if="!loading && $route.name !== 'profile'"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            <span class="text-sentence">{{ $t("message.cancel") }}</span>
          </button>
          <button v-if="!loading" type="submit" class="btn btn-primary">
            <span>{{ $t("message." + saveButtonState) }}</span>
          </button>
          <button v-else class="btn disabled d-flex" type="button" disabled>
            <div
              style="height: 12px; width: 12px"
              class="spinner-border"
              role="status"
            >
              <span class="visually-hidden">{{ $t("message.loading") }}</span>
            </div>
            <span class="ms-2">{{ $t("message.submitting") }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import Checkbox from "@/views/components/simple/Checkbox";
import SelectSearch from "@/views/components/simple/SelectSearch";
import ConfirmDelete from "@/views/components/modals/ConfirmDelete";

export default {
  components: { Checkbox, SelectSearch },
  data() {
    return {
      errors: [],
      branches: [],
      userSubmissions: false,
      loading: false,
      countries: [],
      id: "",
      country: "",
      firstname: "",
      lastname: "",
      gender: "",
      maritalstate: "",
      transportation: "",
      jobtitle: "",
      education: "",
      street: "",
      streetnumber: "",
      number: "",
      bus: "",
      postalcode: "",
      city: "",
      birthdate: "",
      email: "",
      children: 0,
      newPassword: "",
      confirmPassword: "",
      notifyUser: false,
      stateprovince: "",
      employmentstart: "",
      employmentend: "",
      seniority: "",
      userNodetags: [],
      options: null,
      companybranch: null,
      saveButtonState: "save",
      language: null,
      loadingResetMail: false,
      error: null
    };
  },
  computed: {
    tagIDs() {
      return this.userNodetags ? this.userNodetags.map((tag) => tag.id) : [];
    },
    tagQuery() {
      let params = [];
      for (const id of this.tagIDs) {
        params.push("id_nin=" + id);
      }
      return "/usertags";
    },
  },
  mounted() {
    this.hasSubmissions();
    this.getCountries();
    this.getCompanyBranches();
    this.getOptions();
    this.id = this.userNode.id;
    this.firstname = this.userNode.firstname;
    this.lastname = this.userNode.lastname;
    this.gender = this.userNode.gender;
    this.maritalstate = this.userNode.maritalstate;
    this.transportation = this.userNode.transportation;
    this.jobtitle = this.userNode.jobtitle;
    this.education = this.userNode.education;
    this.street = this.userNode.street;
    this.streetnumber = this.userNode.number;
    this.bus = this.userNode.bus;
    this.postalcode = this.userNode.postalcode;
    this.city = this.userNode.city;
    this.country = this.userNode.country;
    this.birthdate = this.userNode.birthdate;
    this.email = this.userNode.email;
    this.children = this.userNode.children || 0;
    this.jobtitle = this.userNode.jobtitle;
    this.stateprovince = this.userNode.stateprovince;
    this.employmentstart = this.userNode.employmentstart;
    this.employmentend = this.userNode.employmentend;
    this.seniority = this.userNode.seniority;
    this.userNodetags = this.userNode.usertags.filter(item => !item.isHidden);
    this.companybranch = this.userNode.companybranch ? this.userNode.companybranch.id : null;
    this.language = this.userNode.language;
    this.error = null;
  },
  methods: {
    async resetPassword(){
      this.error = null
      try {
        this.loadingResetMail = true;
        await this.axios.post("/mailer/mailreset", {
          email: this.email
        });
        this.error =
          this.$t("message.password_reset_mail_sent", { email: this.email }) + ".";
        // console.log('post',post.data)
      } catch (err) {
        // console.log(err);
        this.error =
          err.response.data.message[0].messages[0].message ||
          "Authentication failure";
      }
      this.loadingResetMail = false
    },
    async hasSubmissions() {
      const get = await this.axios.get(
        "/checkupsubmissions?authorid=" + this.userNode.id
      );
      const subl = get.data.length;
      this.userNodeSubmissions = subl;
    },
    async confirmDelete(id) {
      //anonymize user
      const anonUser = {
        firstname: "",
        lastname: "Deleted user",
        gender: null,
        maritalstate: null,
        transportation: null,
        jobtitle: "",
        education: null,
        street: "",
        number: "",
        bus: "",
        postalcode: "",
        city: "",
        country: null,
        birthdate: moment().format("YYYY-MM-DD"),
        email:
          this.randomString(14, this.userNode.username) + "@workvitamins.org",
        children: 0,
        username: this.randomString(14, this.userNode.username),
        stateprovince: "",
        employmentstart: moment().format("YYYY-MM-DD"),
        employmentend: moment().format("YYYY-MM-DD"),
        seniority: null,
        usertags: [],
        swarms: [],
        member: [],
        base: [],
        companybranch: null,
        isdeleted: true,
      };

      let q = "/users/" + id;
      if (this.$route.name === "profile") {
        q = "/customuser/" + id;
      }

      const curr_user = this.$store.getters.getUser;
      if (curr_user.id === id) {
        //log out
        await this.axios.put(q, {
          ...anonUser,
          isdeleted: true,
        });
        await this.$store.dispatch("logout");
        this.$router.replace("/login");
      } else {
        const put = await this.axios.put(q, anonUser);
        this.$store.commit('setMessage', {
          type: 'success',
          content: 'message.user_has_been_deleted'
        })
        this.$emit("userSaved", put.data);
      }
    },
    handleDelete() {
      // console.log("handledelete");
      this.$store.dispatch("openModal", {
        component: ConfirmDelete,
        properties: {
          id: this.userNode.id,
          title: this.userNode.firstname + " " + this.userNode.lastname,
        },
        options: {
          centered: true,
        },
        events: {
          submitModal: this.confirmDelete,
        },
      });
    },
    addTag(val) {
      //console.log('val', val)
      if (val) {
        this.userNodetags.push(val);
      }
    },
    removeTag(id) {
      let index = this.userNodetags.findIndex((item) => id == item.id);
      this.userNodetags.splice(index);
    },
    getList(key) {
      const list = this.options.listoptions.filter((option) => {
        return option.key === key;
      });
      // console.log('getting list', key)
      return list[0].options;
    },
    async getOptions() {
      const get = await this.axios.get("/settings/fieldoptions");
      this.options = get.data;
    },

    isUserValid() {
      this.errors = [];
      let valid = true;

      // Since login and password reset need a minimum length, we'd need to cover this on user registration as well
      // Otherwise we can make users with shorter passwords who cannot log in
      if (this.newPassword && this.newPassword.length < 6) {
        valid = false;
        this.errors.push("password_too_short");
      }

      if (this.newPassword !== this.confirmPassword) {
        valid = false;
        this.errors.push("passwords_do_not_match");
      }

      if (!this.id && !this.newPassword) {
        valid = false;
        this.errors.push("password_is_required");
      }

      if (!this.email) {
        valid = false;
        this.errors.push("email_is_required");
      }
      if (!this.firstname) {
        valid = false;
        this.errors.push("firstname_is_required");
      }

      if (!this.lastname) {
        valid = false;
        this.errors.push("lastname_is_required");
      }

      return valid;
    },

    async saveUser() {
      this.loading = true;
      const user = {
        firstname: this.firstname,
        lastname: this.lastname,
        gender: this.gender,
        maritalstate: this.maritalstate,
        transportation: this.transportation,
        jobtitle: this.jobtitle,
        education: this.education,
        street: this.street,
        number: this.streetnumber,
        bus: this.bus,
        postalcode: this.postalcode,
        city: this.city,
        country: this.country,
        birthdate: this.birthdate,
        email: this.email,
        children: this.children,
        username: this.firstname + this.lastname,
        stateprovince: this.stateprovince,
        employmentstart: this.employmentstart || null,
        employmentend: this.employmentend || null,
        seniority: this.seniority,
        usertags: this.userNodetags,
        companybranch: this.companybranch,
        language: this.language
      };

      if (this.newPassword && this.newPassword === this.confirmPassword) {
        user.password = this.newPassword;
      }
      let result = null;
      if (this.isUserValid()) {
        try {
          if (this.id) {
            let q = "/users/" + this.id;
            if (this.$route.name === "profile") {
              q = "/customuser/" + this.id;
            }
            const put = await this.axios.put(q, user);
            result = put.data;
          } else {
            const post = await this.axios.post("/users", user);
            result = post.data;
            if (this.notifyUser) {
              await this.axios.post("/customuser/notify/" + result.id, {
                url: window.location.origin,
              });
            }
          }
          this.$store.dispatch("closeModal");
          this.$store.commit('setMessage', {
            type: 'success',
            content: 'message.user_has_been_saved',
            duration: 3000,
          })
          this.$emit("userSaved", result);
        } catch (error) {
         this.$store.commit('setMessage', {
           type: 'danger',
           content: error.message,
           duration: 3000,
         })
        }
      }
      this.saveButtonState = "saved";
      this.loading = false;
      setTimeout(()=>{
        this.saveButtonState = "save";
      }, 1500)
    },
    async getCountries() {
      const f = await axios.get("/countries.json");
      this.countries = f.data;
    },
    async getCompanyBranches() {
      const { data: branches } = await this.axios.get('/companybranches');
      this.branches = branches;
    }
  },
  // mounted() {
  //
  // },
  props: ["userNode", "type"],
};
</script>

<style scoped>
.btn-delete:hover {
  text-decoration: underline currentColor;
}

#password_reset_button img{
  height: 16px;
  filter: invert(0%) sepia(2%) saturate(7500%) hue-rotate(39deg) brightness(98%) contrast(101%);
}

#password_reset_button{
  height: 42px;
  margin-bottom: 15px;
  
}

#password_reset_row{
  align-items: flex-end;
  padding-right: calc(var(--bs-gutter-x)/ 2);
  justify-content: space-between;
}
</style>